/**
 * 保存数据
 * @param name
 * @param value
 */
function setItem(name, value) {
	if (!name) {
		return
	}
	if (value === undefined) {
		return;
	}
	localStorage.setItem(name, value)
}


function getItem(name) {
	if (!name) {
		return null
	}
	return localStorage.getItem(name)
}

function removeItem(name) {
	localStorage.removeItem(name)
}

function setJsonItem(name, value) {
	if (!name) {
		return
	}
	if (value === undefined) {
		return;
	}
	localStorage.setItem(name, JSON.stringify(value))
}

function getJsonItem(name) {
	if (!name) {
		return null
	}
	let value = localStorage.getItem(name)
	if (!value) {
		return null;
	}
	return JSON.parse(value)
}

function getUserInfo() {
	return getJsonItem("user_info")
}

function setUserInfo(user_info) {
	return setJsonItem("user_info", user_info)
}



export default {
	setItem,
	getItem,
	removeItem,
	setJsonItem,
	getJsonItem,
	getUserInfo,
	setUserInfo,
}
