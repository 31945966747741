<template>
	<!-- HEADER START -->
	<header>
		<div id="header-sticky" class="header-area header-area-white transparent-header pt-10 pb-10">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-xl-2 col-lg-2 col-md-4 col-10 d-flex align-items-center">
						<div class="logo">
							<router-link to="/home"> <img src="/static/logo2.png?t=10" style="width: 130px; height: 42px" alt=""> </router-link>
						</div>
					</div>
					<div class="col-xl-10 col-lg-10 col-md-7 col-8 d-none d-md-block">
						<div class="header-btn f-right d-none d-sm-none d-md-block d-md-none d-lg-block">
<!--							<router-link to="/home" class="btn btn-white"><i class="fa fa-lock"></i>注册会员</router-link>-->
						</div>
						<div class="main-menu colormenu d-block">
							<nav id="mobile-menu">
								<ul>
									<li><router-link to="/home">首页</router-link></li>
									<li><router-link to="/product">产品服务</router-link></li>
									<li><router-link to="/aboutMe">关于我们</router-link></li>
									<li><router-link to="/joinUs">加入我们</router-link></li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="col-2 col-md-1">
						<div class="side-menu-icon bar-two d-lg-none text-end">
							<button class="side-toggle"><i class="fa fa-bars" aria-hidden="true"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
	<!-- HEADER END -->
</template>

<script>

	export default {
		name: 'MainHeader',
		data() {
			return {
				activeIndex: "1",
				current_path: "",

			}
		},
		watch: {
			$route(to, from) {
				this.current_path = to.path;
				// console.log(this.current_path)
			}
		},
		created() {
			this.current_path = this.$route.path;

		},
		mounted() {
			this.initMenu();
			$(window).on('scroll', this.onScroll);
		},
		beforeDestroy() {
			$(window).off('scroll', this.onScroll);
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},

			initMenu(){
				$('#mobile-menu').meanmenu({
					meanMenuContainer: '.mobile-menu',
					meanScreenWidth: "992"
				});

				$('.side-toggle').on('click', function () {
					$('.side-info').addClass('info-open');
					$('.offcanvas-overlay').addClass('overlay-open');
				})

				$('.side-info-close,.offcanvas-overlay').on('click', function () {
					$('.side-info').removeClass('info-open');
					$('.offcanvas-overlay').removeClass('overlay-open');
				})

				// One Page Nav
				var top_offset = $('.header-area').height() - 10;
				$('.main-menu nav ul').onePageNav({
					currentClass: 'active',
					scrollOffset: top_offset,
				});
			},

			onScroll() {
				let scroll = $(window).scrollTop();
				if (scroll < 245) {
					$(".header-sticky").removeClass("sticky");
				} else {
					$(".header-sticky").addClass("sticky");
				}
			}
		}
	}
</script>


<style lang="scss" scoped>

	/*header*/
	.header {
		background: #fff;
		padding: 10px 0 8px;
		position: relative;
		z-index: 9;
		height: 81px;
		border-bottom: 1px solid #c9c9c9;
		overflow: hidden;
	}


	.transparent-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 9;
		padding: 10px 0;
	}


	.main_nav {
		font-size: 16px;
		height: 32px;
		flex: 1;
		max-width: 300px;
	}

	/*.main_nav li {*/
	/*	float: left;*/
	/*	margin: 0 1px*/
	/*}*/

	.main_nav .nav_item {
		color: #fff;

		a {
			color: #fff;
			text-decoration: none;
		}
	}

	.main_nav a:hover, .main_nav_curr {
		color: #fff;
		border-bottom: 2px solid #fff
	}

	.main_nav a:hover {
		text-decoration: none;
	}

</style>
