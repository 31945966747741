<template>
	<div id="app">
		<MainHeader v-if="showMain"></MainHeader>
		<router-view/>
		<MainFooter v-if="showMain" ></MainFooter>
	</div>
</template>
<script>
	import MainHeader from "./components/MainHeader";
	import MainFooter from "./components/MainFooter";

	export default {
		name: "App",
		components: {
			MainHeader,
			MainFooter
		},
		data() {
			return {
				showMain: true
			}
		},

		created() {
			console.log();
			let href = window.location.href;
			let shareIndex = href.lastIndexOf("/share");
			let saleIndex = href.lastIndexOf("/pgy");
			if (shareIndex > 0 || saleIndex > 0) {
				this.showMain = false;
				// eslint-disable-next-line no-empty
			} else {
				this.showMain = true;
			}

		},
		methods: {}
	}
</script>


<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.login-form {

	}
</style>
