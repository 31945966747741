export function removeToken() {
	localStorage.removeItem("x-app-token");
}

export function getToken() {
	let value = localStorage.getItem("x-app-token");
	// console.log("返回 cookie token =:" + value)
	if (!value) {
		return null;
	}
	try {
		let tokenInfo = JSON.parse(value)
		let token = tokenInfo['token'];
		let expire = tokenInfo['expire'];
		if (!expire) {
			return null;
		}
		expire = parseInt(expire);
		let now_time = new Date().getTime()
		if (expire > now_time) { // 未过期
			return token
		}
		// 已过期
		removeToken();
		return null;
	} catch (e) {
		console.log("保存的token信息错误！");
	}
	return null;
}

export function setToken(token, seconds = 0) {
	// console.log("设置token:" + token + ",有效时间:" + seconds)
	let expire_date = new Date()
	expire_date.setTime(expire_date.getTime() + seconds * 1000) // 过期时间
	let tokenInfo = {
		token: token,
		expire: expire_date.getTime(),
	};
	let value = JSON.stringify(tokenInfo)
	localStorage.setItem("x-app-token", value);
}
