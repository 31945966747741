/**
 * 一些简单的配置 ， 都在这里
 */

/**
 * 开发环境使用，正式环境请设置空
 */

import global_var from '../global_var'

const ApiPrefix = global_var.ApiPrefix;      //prod

export default {

    httpApi: {
        createPicCode: `${ApiPrefix}/account/create_pic_code`,      // 创建图形验证码
        sendRegSmsCode: `${ApiPrefix}/account/send_reg_sms_code`,      // 发送注册短信验证码
        phoneReg: `${ApiPrefix}/account/phone_reg`,      // 手机号注册

        phoneLogin: `${ApiPrefix}/account/phone_login`,      // 手机号/uid,密码登录
        logout: `${ApiPrefix}/account/logout`,      // 退出登录

        balance_get_info:  `${ApiPrefix}/action/balance/get_info`, //查询余额
        package_get_info:  `${ApiPrefix}/action/package/get_info`, //查询包月套餐
        GetUserBaseInfo: `${ApiPrefix}/account/base_info`,       //获取用户信息

        getHomeInfo: `${ApiPrefix}/web/home/get_info`, // 获取首页配置数据
        get_message: `${ApiPrefix}/get_message`,

        advertGetList: `${ApiPrefix}/advert/get_list`, // 获取广告数据
        newsGetList: `${ApiPrefix}/news/get_list`, // 获取新闻数据
        newsGroupGetList: `${ApiPrefix}/news_group/get_list`,
        newsGroupGetOptionList: `${ApiPrefix}/news_group/get_option_list`, // 获取新闻分组

        actionTariffQuery: `${ApiPrefix}/action/tariff/query`, // 查询费率
        getBrandConfig: `${ApiPrefix}/config/get_brand_config`, // 获取配置信息

        getNewsDetail: `${ApiPrefix}/news/news_detail`, // 获取新闻详情
        getPayType: `${ApiPrefix}/config/get_pay_type`, // 获取支付方式
        getAppRechargeConfig: `${ApiPrefix}/config/get_app_recharge_config`, //获取App中充值商品列表
        getGoodsInfo: `${ApiPrefix}/config/get_goods_info`, // 获取广告信息

        makeOrder: `${ApiPrefix}/order/make_order`, //下单
        getQrCode: `${ApiPrefix}/web/qr_code`, //生成二维码
        getOrderDetail: `${ApiPrefix}/pay/user/order/pcPay/detail`, // 获取订单信息
        getOrderResult: `${ApiPrefix}/pay/user/order/pcPay/result`, // 获取订单支付结果
        payB2b: `${ApiPrefix}/pay/user/order/payB2b`, // 对公支付


        sendLoginMessageCode: `${ApiPrefix}/shop/external/dandelion/sendLoginMessageCode`, // 发送登录验证码
        saleCutomerLogin: `${ApiPrefix}/shop/external/dandelion/checkLoginMessageCode`, // 销售积分用户登录
        listPhonePrefixOption: `${ApiPrefix}/product-travel/country/listPhonePrefixOption`, // 获取国家电话区号
    }
}
