<template>
	<div class="footer-area padding-remove footertext-hover">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-2 col-md-4">
					<div class="footer_logo wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
						<img src="/static/logo1.png" alt="logo"/>
					</div>
				</div>
				<div class="col-xl-2 col-lg-2 col-md-4">
					<div class="footer_widget_design wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
						<div class="link_heading">
							<h3 class="link_heading">产品</h3>
						</div>
						<div>
							<li><router-link to="/product">产品介绍</router-link></li>
							<li><router-link to="/product">产品服务</router-link></li>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-3 col-md-4">
					<div class="footer_widget_design fixwidth wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
						<div class="link_heading">
							<h3 class="link_heading">企业</h3>
						</div>
						<div>
							<li><router-link to="/aboutMe">关于我们</router-link></li>
						</div>
					</div>
				</div>
				<div class="col-xl-3 col-lg-2 col-md-4">
					<div class="footer_widget_design wow  fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
						<div class="link_heading">
							<h3 class="link_heading linkspecail">深圳微行网络科技有限公司</h3>
						</div>
						<div class="link_heading linkspecail">
							<li><router-link to="#"><i class="fa fa-map-marker"></i><span class="tp-spaceing">广东省深圳市罗湖区宝安北路宝能慧谷C座16-19</span></router-link></li>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer_bottom pt-60 pb-15">
			<div class="container tp-border">
				<div class="row align-items-center">
					<div class="col-xl-6 col-lg-6 col-md-6">
						<div class="copyright_text pt-20">
							<p>
							Copyright &copy; 深圳微行网络科技有限公司.
								<a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2020141957号</a>
							</p>
						</div>
					</div>
<!--					<div class="col-xl-6 col-lg-6 col-md-6">-->
<!--						<div class="footer_social f-right">-->
<!--							<router-link to="#"><i class="fa fa-wechat"></i></router-link>-->
<!--							<router-link to="#"><i class="fa fa-qq"></i></router-link>-->
<!--							<router-link to="#"><i class="fa fa-vimeo"></i></router-link>-->
<!--							<router-link to="#"><i class="fa fa-facebook-f"></i></router-link>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {PostApi} from '@/api'
	import global_var from "@/global_var";
	import MyStorage from '@/utils/MyStorage'
	import {setToken, removeToken, getToken} from '@/utils/auth'
	import {hex_md5} from '@/utils/md5'
	import {Message} from 'element-ui'

	export default {
		name: 'MainHeader',
		data() {
			return {

			}
		},
		created() {
		},
		methods: {

		}
	}
</script>


<style lang="scss">



</style>
