// 新闻分组列表
console.log('process.env.NODE_ENV',process.env.NODE_ENV)
export const BASE_API = process.env.NODE_ENV === 'production' ? '/' : '/'
export const ApiPrefix = process.env.NODE_ENV === 'production' ? 'http://192.168.3.91:18020' : 'http://192.168.3.91:18020'        //product


export default {
	BASE_API: BASE_API,
	ApiPrefix: ApiPrefix,
	imageUpload: BASE_API + `${ApiPrefix}/file_upload/image_upload`,   // 图片上传
	fileUpload: BASE_API + `${ApiPrefix}/file_upload/file_upload`,    // 文件上传

	// http://www.netphone.com
	imagePathPrefix: process.env.NODE_ENV === 'production' ? '/' : '/',

	brand: "wx",
	pv: "web",
	v: "1.0.0",
	package_name: "com.weseego.h5",
	channel: "1",

}
