import { hex_md5 } from '@/utils/md5'
import MyStorage from '@/utils/MyStorage'
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { getToken } from '@/utils/auth'
import store from '@/store'
import router from '@/router';

import global_var from "../global_var";

// create an axios instance
const service = axios.create({
    baseURL: global_var.BASE_API, // api的base_url
    timeout: 10000 // request timeout
})

let s1 = "7a11c75f"
let s2 = "64d78f99"
let s3 = "224b4851"
let s4 = "491a25a0"


function str2utf8(str) {
    return eval('\'' + encodeURI(str).replace(/%/gm, '\\x') + '\'');
}

function randomNum(minNum, maxNum) {
    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
}

function get_device_id() {
    if (global_var.DEVICE_ID) {
        return global_var.DEVICE_ID;
    }
    let device_id = localStorage.getItem('device_id')
    if (!device_id) {
        device_id = hex_md5(Date.now().toString() + randomNum(10000, 99999))
        MyStorage.setItem('device_id', device_id)
    }
    return device_id
}


function create_sign(params, sign_key) {
    params['pv'] = global_var.pv
    params['v'] = global_var.v
    params['timestamp'] = Date.now()
    params['device_id'] = get_device_id()
    params['package_name'] = global_var.package_name
    params['channel'] = global_var.channel
    // params['uid'] = "default"

    let keys = [];
    for (let k in params) {
        keys.push(k);
    }
    keys.sort();

    let pair_list = [];
    for (let i in keys) {
        if (keys[i] === "sign") {
            continue;
        }
        // var paramVal = StringUtil.EncodeUtf8(params[keys[i]]);
        let paramVal = params[keys[i]];
        if (paramVal === undefined) {
            continue;
        }
        pair_list.push(keys[i] + "=" + paramVal)
    }
    pair_list.push("key=" + sign_key)

    let sign_str = pair_list.join("&");
    let sign_str_utf8 = str2utf8(sign_str);
    let new_sign = hex_md5(sign_str_utf8);
    // console.info(`待签名字符串[${sign_str}]的签名值为[${new_sign}]`)
    return new_sign;
}


// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    let token = getToken()
    // console.log('Cookie中的token[' + getToken() + ']')
    if (token) {
        config.headers['X-Token'] = token // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    // console.log(config)
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data
        // console.log("response data：" + JSON.stringify(res))
        if (res.c !== 0 && res.code !== 0) {
            // Message({
            //     message: res.m || res.msg,
            //     type: 'warning'
            // });

            // Toast(res.m);
            if (res.c === 9) {
                MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    eventBus.$emit("logout", 1);
                }).catch(() => {
                    console.log('cancel')
                })
            }
            return res
        } else {
            return res
        }
    },

    (error) => {
        console.error('net request err:', error)// for debug
        Message({ message: error.message, type: 'warning' })
        return Promise.reject(error)
    })


export function httpGet(option) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.params }
        params["sign"] = create_sign(params, s1 + s2 + s3 + s4)
        // console.log(`GET请求${option.url}参数:${JSON.stringify(params)}`)
        let conf = {
            params: params,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
        service.get(option.url, conf).then((response) => {
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

function qs(params) {
    let pair_list = [];
    for (let it in params) {
        // var paramVal = StringUtil.EncodeUtf8(params[keys[i]]);
        let paramVal = params[it];
        if (paramVal === undefined) {
            continue;
        }
        pair_list.push(encodeURIComponent(it) + "=" + encodeURIComponent(paramVal))
    }
    return pair_list.join("&");
}

export function httpPost(option) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.data }
        params["sign"] = create_sign(params, s1 + s2 + s3 + s4)
        // console.log(`POST请求${option.url}参数:${JSON.stringify(params)}`)
        // console.log("host: " + global_var.BASE_API)
        let data = qs(params)
        // console.log(data)
        let conf = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
        service.post(option.url, data, conf).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function httpPostWithHeader(option, token) {
    return new Promise((resolve, reject) => {
        // console.log(JSON.stringify(option))
        let params = { ...option.data }
        params["sign"] = create_sign(params, s1 + s2 + s3 + s4)
        // console.log(`POST请求${option.url}参数:${JSON.stringify(params)}`)
        // console.log("host: " + global_var.BASE_API)
        let data = qs(params)
        // console.log(data)
        let conf = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'customer-token': token
            }
        }
        service.post(option.url, data, conf).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


/**
 * 构建请求地址
 * @param path 地址
 * @param payload 参数
 * @returns {string} 返回构建后的请求地址
 */
export function buildRequestURL(path, payload) {
    let params = { ...payload }
    params['token'] = getToken()
    params['sign'] = create_sign(params, s1 + s2 + s3 + s4)
    let data = qs(params)
    return global_var.BASE_API + path + '?' + data
}


// export default service
