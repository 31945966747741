import { json } from 'body-parser'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/share',
		name: 'SharePage',
		meta: {
			title: '微行旅游',
			keywords: '微行旅游',
			description: '社交新服务，享受高品质旅游'
		},
		component: () => import('../views/sharePage/index1.vue')
	},
	{
		path: '/webPay',
		name: 'webPay',
		meta: {
			title: '微行旅游-web支付',
			keywords: '微行旅游-web支付',
			description: '社交新服务，享受高品质旅游'
		},
		component: () => import('../views/webPay/index.vue')
	},
	{
		path: '/payResult',
		name: 'payResult',
		meta: {
			title: '微行旅游-支付结果',
			keywords: '',
			description: '社交新服务，享受高品质旅游'
		},
		component: () => import('../views/webPay/paySuccess.vue')
	},
	{
		path: '/product',
		name: 'Product',
		component: () => import('../views/Product.vue')
	},
	{
		path: '/aboutMe',
		name: 'AboutMe',
		component: () => import('../views/about/AboutMe')
	},
	{
		path: '/joinUs',
		name: 'JoinUs',
		component: () => import('../views/about/JoinUs')
	},
	{
		path: '/customerService',
		name: 'Business',
		component: () => import('../views/about/CustomerService')
	},
	{
		path: '/contactUs',
		name: 'ContactUs',
		component: () => import('../views/about/ContactUs')
	},
	{
		path: '/agreementUser',
		name: 'AgreementUser',
		component: () => import('../views/about/AgreementUser')
	},
	{
		path: '/agreementPrivacy',
		name: 'AgreementPrivacy',
		component: () => import('../views/about/AgreementPrivacy')
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import('../views/Help')
	},
	{
		path: '/home_news',
		name: 'HomeNews',
		component: () => import('../views/News.vue')
	},
	{
		path: '/pgy',
		name: 'pgy',
		component: () => import('../views/dandelion/index.vue')
	},
	{
		path: '*',
		redirect: '/Home'
	},
]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
// router.beforeEach((to, from, next) => {
// 	/* 路由发生变化修改页面meta */
// 	console.log(to.meta);
// 	if(to.meta){
// 		let head = document.getElementsByTagName('head');
// 		let meta = document.createElement('meta');
// 		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords);
// 		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
// 		meta.content = to.meta.content;
// 		head[0].appendChild(meta)
// 	}
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}
//
// 	next()
// })

router.beforeEach((to, from, next) => {
	/*********动态修改keywords和description*************/
	if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
		let this_meta = to.matched[to.matched.length - 1].meta
		// console.log(this_meta,'---this_meta---')
		if (this_meta.title) document.title = this_meta.title
		let head = document.getElementsByTagName('head');
		let meta_keyword = document.createElement('meta');
		if (document.querySelector('meta[name="keywords"]')) {
			document.querySelector('meta[name="keywords"]').setAttribute('content', this_meta.keywords)
		} else {
			meta_keyword.setAttribute('name', 'keywords')
			meta_keyword.setAttribute('content', this_meta.keywords)
			head[0].appendChild(meta_keyword)
		}
		let meta_description = document.createElement('meta');
		if (document.querySelector('meta[name="description"]')) {
			document.querySelector('meta[name="description"]').setAttribute('content', this_meta.description)
		} else {
			meta_description.setAttribute('name', 'description')
			meta_description.setAttribute('content', this_meta.description)
			head[0].appendChild(meta_description)
		}
	}
	/**********************************************/

	next()

})

export default router
