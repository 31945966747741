import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global_var from "./global_var";
import ElementUI from 'element-ui';
import Calendar from 'vue-mobile-calendar'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';


import { BootstrapVue } from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './utils/extend.js'

// import './style/base.css';
import './style/responsive.css';
import 'font-awesome/css/font-awesome.min.css'


Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
Vue.use(Calendar)
Vue.prototype.GVar = global_var;
window.eventBus = new Vue();//注册全局事件对象

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
